import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as action from "../../../store/actions/index";
import styled from "@emotion/styled";
import axios from "axios";
import Select from "react-select";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";


const EmailTemplates = () => {

  const [allowedevents, setAllowedEvents] = useState([]);

  useEffect(() => {
    axios.get('/api/emailtemplates')
      .then(res => {
        console.log("res email templates")
        console.log(res)
        setAllowedEvents(res.data.emailtemplates)
      }).catch(err => {
        console.log(err.response)
      })
  })

  const fieldPreviewTranslator = ( field ) => {
    console.log("field")
    console.log(field)
    // let removeEnters = field.replace(/<\/p>/g, '</div>').replace(/<p>/g, '<div>')
    // let addEnters = field.replace(/<\/div>/g, '</p>').replace(/<div>/g, '<p>')
    let html =  field.replace(/<\/p>/g, '</div>').replace(/<p>/g, '<div>')
    return html
  }

  let events = (
    <div className="noevents" style={{ textAlign: "center", padding: 30 }}>
      <h2 style={{ marginLeft: 20 }} className="noevents">
        No Templates Created or Accesible...
      </h2>
    </div>
  );

  events = allowedevents.map((item, index) => (
    <div
      className="d-flex flex-row mb-3"
      key={index}
      style={{
        margin: 10,
        paddingLeft: 30,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      <Link to={`/events/${item._id}`}>
        <Button
          id="eventlistbutton"
          variant="light"
          className="eventlistbutton"
          style={{
            paddingRight: 10,
            paddingLeft: 15,
            paddingBottom: 10,
            opacity: 0.8,
            boxShadow: "3px 3px 9px dimgray",
            border: ".9px solid gray",
            borderRadius: 10,
            minWidth: 280,
            fontFamily: "Verdana",
          }}
        // onClick={() => showEvent(item._id)}
        >
          <div style={{ marginRight: 50 }} className="eventname_label">
            <b>Template Name:</b> {item.email_template_name}
          </div>
          <div style={{ marginRight: 50 }} className="eventname_label">
            <b>Template Description:</b> {item.email_template_desciption}
          </div>
          <div style={{ marginRight: 50 }} className="eventname_label">
            <b>Template Subject:</b> {item.email_template_subject}
          </div>
          <div style={{ marginRight: 50 }} className="eventname_label">
            <b>Template Category:</b> {item.email_template_category}
          </div>
          {/* <div style={{ marginRight: 50 }} className="eventname_label">
            <b>Template Created Date:</b> {item.email_template_created_date}
          </div>
          <div style={{ marginRight: 50 }} className="eventname_label">
            <b>Template Created By:</b> {item.email_template_created_by}
          </div> */}
          <div style={{ marginRight: 50 }} className="eventname_label">
            <b>Template Updated Date:</b> {item.email_template_updated_date}
          </div>
          <div style={{ marginRight: 50 }} className="eventname_label">
            <b>Template Updated By:</b> {item.email_template_updated_by}
          </div>
          <div style={{ marginRight: 50, marginTop: 8}} className="eventname_label">
            {/* <b>Template Body:</b>  */}
            <div style={{fontSize: '9px', borderRadius: '5px', border: '1px solid gray', padding: 20}} dangerouslySetInnerHTML={{ __html: fieldPreviewTranslator(item.email_template_body) }} />
          </div>
        </Button>
      </Link>
    </div>
  ));

  return (
    <div>
      <h1
        className="eventlist_titles"
        style={{
          textAlign: "center",
          marginTop: 30,
          marginBottom: 25,
        }}
      >
        <b
          style={{
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
          }}
        >
          Email Templates
        </b>
      </h1>
          <hr></hr>
          <div style={{fontSize: '23px', marginLeft: '46px', fontWeight: 'bold'}} className="nav-item">Category: General</div>
      <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
        {events}
      </div>
    </div>
  );
};

export default EmailTemplates;
