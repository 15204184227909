import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";
import { usePDF } from 'react-to-pdf';
import "./PrintTicket.css";

const PrintTickets = (props) => {

const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});


  const reduxState = useSelector((state) => state);

  const [isPrinting, setIsPrinting] = useState(false);

  const [guestsSelected, setguestsSelected] = useState([]);
  const [eventsSelected, seteventsSelected] = useState([]);

  const [qrobject, setQrObject] = useState({
    id: props.match.params.guestId,
    dashId: props.match.params.eventId,
  });

  useEffect(() => {
    try {
      if (reduxState.dashboard.dashboard != null || undefined) {
        let eventchosen = reduxState.dashboard.dashboard.filter(
          (event) => event._id === props.match.params.eventId
        );

        let guestschosen = eventchosen[0].lists;

        console.log("eventchosen[0].lists");
        console.log(eventchosen[0].lists);

        seteventsSelected(eventchosen)
        setguestsSelected(guestschosen);
      }
    } catch {
      console.log("setting error");
    }
  }, [props.match.params.eventId, reduxState.dashboard.dashboard]);

  //   if(reduxState.dashboard.dashboard != null || undefined){
  //   setguestsSelected(
  //     reduxState.dashboard.dashboard.filter(
  //       (event) => event._id === props.match.params.eventId
  //     )
  //   )
  //     }

  const printItems = () => {
    setIsPrinting(true);
    setTimeout(() => {
      window.print();
    }, 500);
    setTimeout(() => {
      setIsPrinting(false);
    }, 2000);
  };

  return (
    <div 
    ref={targetRef}>
      {!isPrinting ? (
          <div>
        <div style={{ marginTop: 50, marginBottom: "20px", marginLeft: 20, position: "relative" }}>
          <button
            style={{
              fontSize: 18,
              width: "380px",
              fontFamily: "Verdana, Geneva, Helvetica",
              background: "#E8E8E8",
              padding: 10,
              borderRadius: "5px",
              marginBottom: "20px",
              border: "1px solid black"
            }}
            // onClick={() => toPDF()}
            onClick={printItems}
          >
            <b >Print Tickets or Download PDF</b>
          </button>
          <h4><b style={{marginLeft: 20}}>Note: Choose Print or Save to PDF</b></h4>
          </div>
         
        </div>

      ) : null}
      {guestsSelected.map((item) => (
        <div
          key={item._id}
          style={{
            paddingLeft: 100,
            paddingTop: 100,
            paddingBottom: 680,
            border: ".9px solid gray",
          }}
        >
            {eventsSelected[0].name?
          <div className="ticketfields">
            <b>Event Name:</b> {eventsSelected[0].name}
          </div>
          : null}
          <div className="ticketfields">
            <b>Name:</b> {item.list}
          </div>
          <div className="ticketfields">
            <b>Organization:</b> {item.organization}
          </div>
          <div className="ticketfields">
            <b>Phone:</b> {item.phone}
          </div>
          <div className="ticketfields">
            <b>Email:</b> {item.email}
          </div>
          <div className="ticketfields">
            <b>Table/Row #:</b> {item.table_row_number}
          </div>
          <div className="ticketfields">
            <b>Seat #:</b> {item.seat_number}
          </div>
          <div className="ticketfields">
            <b>Ticket Type:</b> {item.tickettype}
          </div>
          <div
            style={{
              paddingLeft: 20,
              marginBottom: 10,
              marginTop: 30,
              paddingBottom: 20,
              fontFamily: "Georgia, Helvetica",
            }}
          >
            <QRCode
              size={250}
              value={JSON.stringify({
                qrobject,
              })}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default PrintTickets;

// As a Class Component

// import React, {Component} from 'react'
// import { useDispatch, useSelector } from "react-redux";
// import QRCode from "react-qr-code";
// import "./PrintTicket.css"
// import { connect } from "react-redux";

// class PrintTickets extends Component {

//     constructor(props){
//       super(props);
//       this.state = {
//         listSelected: [],
//         guestsSelected:[],
//         qrobject : {
//             id: props.match.params.guestId,
//             dashId: props.match.params.eventId,
//         }
//       }
//     }

//     componentDidMount(){
//         console.log("listSelected")
//         console.log(this.state.listSelected)
//     // console.log("Randosm try")
//     // console.log("props.match.params")
//     // console.log(props.match.params.eventId)
//     // console.log(this.props.state.dashboard.dashboard.filter((event) => event._id == props.match.params.eventId))

//     try{

//         let eventchosen = this.props.state.dashboard.dashboard.filter(
//             (event) => event._id === this.props.match.params.eventId
//         )
//         let guestschosen = eventchosen[0].lists
//         console.log("eventchosen[0].lists")
//         console.log(eventchosen[0].lists)

//         this.setState({listSelected: guestschosen})
//         }

//         catch{
//             console.log("setting error")
//         }
//     }

//         render() {

//             try{

//                 let eventchosen = this.props.state.dashboard.dashboard.filter(
//                     (event) => event._id === this.props.match.params.eventId
//                 )
//                 let guestschosen = eventchosen[0].lists

//                 this.setState({guestsSelected: eventchosen})
//                 this.setState({listSelected: guestschosen})

//                 }

//                 catch{
//                     console.log("setting error")
//                 }

//                 console.log("listSelected")
//                 console.log(this.state.listSelected)

//         return (
//             <div>
//             <div style={{marginTop: 25, marginLeft: 20, position: "absolute"}}>
//                 <button style={{fontSize: 18, width: "180px", fontFamily: "Verdana, Geneva, Helvetica", background: "lightgray", padding: 10, borderRadius:"5px", marginBottom:"3%"}}
//                 onClick={() => window.print()}><b>Print Tickets</b></button>
//             </div>
//             {this.state.listSelected.map((item) => (
//                 <div key = {item._id}
//                 style={{ paddingLeft: 100, paddingTop: 100, paddingBottom: 680, border: ".9px solid gray" }}
//                 >
//                 <div className="ticketfields"><b>Event Name:</b> {this.state.guestsSelected[0].name}</div>
//                 <div className="ticketfields"><b>Name:</b> {item.list}</div>
//                 <div className="ticketfields"><b>Organization:</b> {item.organization}</div>
//                 <div className="ticketfields"><b>Phone:</b> {item.phone}</div>
//                 <div className="ticketfields"><b>Email:</b> {item.email}</div>
//                 <div className="ticketfields"><b>Table/Row #:</b> {item.table_row_number}</div>
//                 <div className="ticketfields"><b>Seat #:</b> {item.seat_number}</div>
//                 <div className="ticketfields"><b>Ticket Type:</b> {item.tickettype}</div>
//                 <div
//                     style={{
//                     paddingLeft: 20,
//                     marginBottom: 10,
//                     marginTop: 30,
//                     paddingBottom: 20,
//                     fontFamily: "Georgia, Helvetica",
//                     }}
//                 >
//                     <QRCode
//                     size={250}
//                     value={JSON.stringify(
//                         this.state.qrobject,
//                     )}
//                     />
//                 </div>
//                 </div>
//             ))}
//             </div>
//         );
//         };
// }

// const mapStateToProps = (state, ownProps) => {
//     return {
//       state: state,
//       user: state.auth.user,
//       isAuthenticated: state.auth.isAuthenticated
//     }
//   }

//   export default connect(mapStateToProps, null)(PrintTickets)
