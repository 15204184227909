import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {store} from './store'
import 'bootstrap/dist/css/bootstrap.css';
// import 'semantic-ui-css/semantic.min.css'
import { PersistGate } from 'redux-persist/integration/react';

import { persistStore } from "redux-persist";
let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
     <PersistGate loading={null} persistor={persistor}>
    <App/>
    </PersistGate>
  </Provider>,
document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
