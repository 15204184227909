import React from "react";
import { Link } from "react-router-dom";
import Fragment from "../../utils/Fragment";
import "./Landing.css";
// import vid from '../Assets/vid.mp4'
// import vid from "../Assets/bc.mp4";
// import Button from "react-bootstrap/Button";
import partygraphic from "../Assets/aboutus.png";
// import partylinegraphic from "../Assets/linewow.jpg";
import securityline from "../Assets/securityline.jpeg";
import qrgraphic from "../Assets/qrvectorimage.png";
// import excelgraphic from "../Assets/excelimport.jpeg";
import Footer from "../Footer/Footer.js";
// import phoneapp from "../Assets/phoneapp.jpeg";
import phoneapp2 from "../Assets/animated_phoneapp.gif";
// import confetti from "../Assets/confetti.gif";
// import axios from "axios"
import excelintegration2 from "../Assets/excelintegration2.png"
import relationshipssmooth from "../Assets/relationshipssmooth.jpg"
import apicloud from "../Assets/apicloud.jpg"
import qrlanding from "../Assets/qrlanding.png"
import qrlandingwhite from "../Assets/qrlandingwhite.png"
import qrlandinggraphic from "../Assets/qrlandinggraphic.png"
import activityvector from "../Assets/activityvector.jpg"


const Landing = () => {

  return (
    <div>
      <Fragment>
        <div className="">
          {/* <div dangerouslySetInnerHTML={{ __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${vid}"
          class="VideostyleBanner"
        />,
      ` }}></div> */}
          <div className="" style={{ marginTop: 39 }}>
            {/* <h1
              style={{
                textAlign: "center",
                fontFamily:"Verdana, Georgia",
                color: "#505050",
              }}
              className="display-4"
            >
              Event Sensei <br />
            </h1> */}
            <h1
              className="landingheadings2"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                color: "#505050",
                marginBottom: "18px",
              }}
            >
              Event Check-In and Management
            </h1>
            {/* <img
            src={partylinegraphic}
            position="absolute"
            height="270"
            width="550"
            alt="line of guests"
            style={{
              maxWidth: "100%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          /> */}

            <img
              src={securityline}
              className="securityline"
              position="absolute"
              alt="line of guests"
              style={{
                maxWidth: "100%",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />

            {/* <img
            src={securityline}
            position="absolute"
          width="550"
            height="280"
            alt="line of guests"
            style={{
              maxWidth: "100%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          /> */}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 25,
                marginBottom: 30,
              }}
            >
              <Link to="/signup" className="btn">
                <button
                  className="getStartedButton"
                  style={
                    {
                      // background: "linear-gradient(to right, #F67280 , #F75D59)",
                      // background: "linear-gradient(to bottom right, #F67280 , #CC0000)",
                      // border: "none",
                      // color: "white",
                      // padding: 15,
                      // fontSize: 15,
                      // width: "18%",
                      // fontFamily: "Verdana"
                    }
                  }
                >
                  Get Started
                </button>
              </Link>
            </div>

            {/* <div className="landingbuttons" style={{
              marginTop: 25,
              marginBottom: 35,
              fontFamily:"Verdana, Georgia",
              color: "#505050"
              }}>

              <Link to="/signup" className="btn">
                <button
                className="signupbutton"
                  style={{
                    borderRadius: "34px",
                    boxShadow: "0px 3px 10px black",
     
                  }}
                >
                  Sign Up
                </button>
              </Link>

              <Link to="/login" className="btn pull-right">
                <button
                  className="loginbutton"
                  style={{
                    borderRadius: "34px",
                    boxShadow: "0px 3px 10px black",
                  }}
                >
                  Login
                </button>
              </Link>
              
            </div> */}

            <hr className="my-4" />
            <div style={{ textAlign: "center" }}>
              <h1
                className="landingheadings"
                style={{
                  zIndex: 100,
                  textAlign: "center",
                  padding: 10,
                  paddingLeft: 40,
                  paddingRight: 40,
                  color: "#484848",
                }}
              >
                Check Guests into your Events using our App
              </h1>
              {/* <h3>Login below to Manage your Guest Lists.</h3> */}

              <img
                src={phoneapp2}
                position="absolute"
                className="phoneapp"
                // width="250"
                // height="400"
                alt="line of guests"
                style={{
                  maxWidth: "100%",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 5,
                }}
              />
            </div>
          </div>

          {/* Horizonatally 2.6 times bigger */}
          <hr className="my-4" />
          <br />
          <div>
            <h1
              className="landingheadings"
              style={{
                textAlign: "center",
                padding: 10,
                paddingLeft: 40,
                paddingRight: 40,
                color: "#484848",
              }}
            >
              Create and Email Guests QR codes for faster Checking-In
            </h1>

            {/* <h2
              style={{
                color: "darkred",
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              (Subscription Required)
            </h2>
            <br /> */}
            {/* <br />
            <div>
              <img
                src={qrgraphic}
                position="absolute"
                width="250"
                height="250"
                alt="qr code scanning"
                style={{
                  padding: 2,
                  maxWidth: "100%",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "5%",
                }}
              />
            </div> */}

            <br />
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <img
              className="qrlanding"
                src={qrlandinggraphic}
                position="absolute"
                width="450px"
                height="450px"
                alt="qr code scanning"
                style={{
                  padding: 2,
                  maxWidth: "100%",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "2%",
                }}
              />
            </div>
          </div>

          <hr></hr>
          <h1
            className="landingheadings"
            style={{
              textAlign: "center",
              padding: 10,
              paddingLeft: 40,
              paddingRight: 40,
              color: "#484848",
            }}
          >
            Review our Reports and Analytics of your Events
          </h1>
          <br />
          {/* Horizonatally .685% bigger */}
          <img
            src={partygraphic}
            position="absolute"
            className="partygraphic"
            alt="reports and analytics"
            style={{
              width: "650px",
              height: "450px",
              padding: 10,
              maxWidth: "100%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <hr className="my-4" />

          <h1
            className="landingheadings"
            style={{
              textAlign: "center",
              padding: 5,
              color: "#484848",
            }}
          >
            Import Guest Lists & Download Reports
          </h1>
          <br />
          {/* <img
            src={excelgraphic}
            position="absolute"
            width="300"
            height="300"
            alt="qr code scanning"
            style={{
              padding: 2,
              maxWidth: "100%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "1%",
            }}
          /> */}
          <img
            src={excelintegration2}
            position="absolute"
            className="excelintegration"
            alt="qr code scanning"
            style={{
              padding: 2,
              maxWidth: "100%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "1%",
            }}
          />
          <hr className="my-4" />

          <div>
              <h1
                className="landingheadings"
                style={{
                  textAlign: "center",
                  paddingTop: 10,
                  paddingLeft: 40,
                  paddingRight: 40,
                  color: "#484848",
                }}
              >
                Create Volunteer Accounts and Track their Activity
              </h1>
              <h3
                style={{
                  textAlign: "center",
                  paddingTop: 10,
                  paddingLeft: 40,
                  paddingRight: 40,
                  color: "#484848",
                }}
              >
                Limit Volunteer Permissions and Access to Chapter Groups
              </h3>
              <img
                src={activityvector}
                className="activityvector"
                position="absolute"
                width="618"
                height="417"
                alt="qr code scanning"
                style={{
                  maxWidth: "100%",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </div>

            <hr className="my-4" />

          <div style={{}}>
            <h1
              className="landingheadings"
              style={{
                textAlign: "center",
                padding: 10,
                paddingLeft: 40,
                paddingRight: 40,
                color: "#484848",
              }}
            >
              Create Custom Relationships
            </h1>
            <br />
            <div>
              <img
                className="customrelationships"
                src={relationshipssmooth}
                position="absolute"
                width="477"
                height="350"
                alt="qr code scanning"
                style={{
                  padding: 2,
                  maxWidth: "100%",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "5%",
                }}
              />
            </div>

            <hr></hr>
            <div>
              <h1
                className="landingheadings"
                style={{
                  textAlign: "center",
                  paddingTop: 10,
                  paddingLeft: 40,
                  paddingRight: 40,
                  color: "#484848",
                }}
              >
                Access our API to integrate your applications with ours
              </h1>
              <img
                src={apicloud}
                className="apicloud"
                position="absolute"
                width="520"
                height="450"
                alt="qr code scanning"
                style={{
                  maxWidth: "100%",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </div>
          </div>

          <div
            id="test123"
            style={{
              height: 320,
              borderTop: "1px solid #E0E0E0",
              textAlign: "center",
            }}
          >
            <h1
              className="landingheadings2"
              id="tryfree_heading"
              style={{
                padding: 20,
                marginTop: 110,
                // color: "#505050",
                color: "whitesmoke",
                textShadow: "gray 2px 2px 10px",
                // textShadow: "white 4px 2px 7px"
              }}
            >
              Try free for 25 guests or Less
            </h1>
          </div>
        </div>

        <Footer />
      </Fragment>
    </div>
  );
};

export default Landing;
