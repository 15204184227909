import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QRCode from "react-qr-code";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import GuestTicket from "./guestTicket";
import "./EditGuestModal.css";
import { Link, Redirect, useHistory } from "react-router-dom";

const EditGuestModal = (props) => {
  const [viewTicket, setViewTicket] = useState(false);

  const history = useHistory();

  const [formData, setFormData] = useState({
    guestname: props.list,
    email: props.email,
    phone: props.phone,
    tickettype: props.tickettype,
    guest_details: props.guest_details,
    organization: props.organization,
    table_row_number: props.table_row_number,
    seat_number: props.seat_number,
  });

  useEffect(() => {
    console.log("formData");
    console.log(formData);

    console.log("props over here");
    console.log(props);
  }, []);

  //Single Event Handler Approach:
  let onChange = (e) => {
    if (formData.guestname === "") {
      formData.guestname = props.list;
    }

    if (formData.email === "") {
      formData.email = props.email;
    }

    if (formData.phone === "") {
      formData.phone = props.phone;
    }

    if (formData.tickettype === "") {
      formData.tickettype = props.tickettype;
    }

    if (formData.guest_details === "") {
      formData.guest_details = props.guest_details;
    }

    if (formData.organization === "") {
      formData.organization = props.organization;
    }

    if (formData.table_row_number === "") {
      formData.table_row_number = props.table_row_number;
    }

    if (formData.seat_number === "") {
      formData.seat_number = props.seat_number;
    }
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    // this.setState({ [e.target.name]:
    //     e.target.value});

    console.log(formData.name);
    console.log(formData.email);
  };

  const onSubmit = (e) => {
    // e.preventDefault();

    console.log("submit");
    console.log(formData.guestname);
    console.log(formData.email);
    console.log(formData.phone);
    console.log(formData.tickettype);
    console.log(formData.guest_details);
    console.log(formData.organization);
    console.log(formData.table_row_number);
    console.log(formData.seat_number);

    props.editGuestList(
      props.dashId,
      props.id,
      formData.guestname,
      formData.email,
      formData.phone,
      formData.tickettype,
      formData.guest_details,
      formData.organization,
      formData.table_row_number,
      formData.seat_number
    );
    props.onHide();

    toast.success(
      <div style={{ fontSize: "16px" }}>
        <b>Guest Edited!</b>
      </div>,
      {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );

    // Editing multiple times in a row is causing a glitch
    // With Guest not editing after you try to edit multiple times.
    // So adding Reload below which fixes the issue
    // window.location.reload()
  };

  const closeEditModal = () => {
    props.onHide();
    setViewTicket(false);
  };

  const guestprofile = () => {
    //Redirect to another route
    history.push(`/guests/${props.dashId}/${props.id}`);
  };

  const printDocument = () => {
    const input = document.getElementById("divToPrint");

    try {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(imgData, "JPEG", 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("ticket_guest_eventsensei.pdf");
      });

      toast.success(
        <div style={{ fontSize: "16px" }}>
          <b>Guest ticket downloaded!.</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } catch {
      toast.error(
        <div style={{ fontSize: "16px" }}>
          <b>An error occured downloading guest ticket.</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{ fontSize: "21px" }}
            className="editmodaltitle"
            id="contained-modal-title-vcenter"
          >
            <b style={{ fontFamily: "Verdana, Geneva, Helvetica" }}>
              Edit Guest2
            </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!viewTicket ? (
            <div>
              <form onSubmit={onSubmit}>
                <div>
                  <div className="form-group-custom">
                    <label className="editmodaltext" htmlFor="name">
                      <b>Name</b>
                    </label>
                    <input
                      id="name"
                      type="text"
                      name="guestname"
                      className="form-control form-control-md"
                      style={{ padding: "5px", border: ".5px solid black" }}
                      placeholder="Name..."
                      defaultValue={props.list}
                      onChange={(e) => onChange(e)}
                    />
                  </div>

                  <div className="form-group-custom">
                    <label className="editmodaltext" htmlFor="organization">
                      <b>Organization</b>
                    </label>
                    <input
                      id="organization"
                      type="text"
                      name="organization"
                      className="form-control form-control-md"
                      style={{ padding: "5px", border: ".5px solid black" }}
                      placeholder="Organization..."
                      defaultValue={props.organization}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="form-group-custom">
                    <label className="editmodaltext" htmlFor="email">
                      <b>Email </b>
                    </label>
                    <input
                      id="email"
                      type="text"
                      name="email"
                      className="form-control form-control-md"
                      style={{ padding: "5px", border: ".5px solid black" }}
                      placeholder="Email..."
                      defaultValue={props.email}
                      onChange={(e) => onChange(e)}
                    />
                  </div>

                  <div className="form-group-custom">
                    <label className="editmodaltext" htmlFor="phone">
                      <b>Phone</b>
                    </label>
                    <input
                      id="phone"
                      type="text"
                      className="form-control form-control-md"
                      name="phone"
                      style={{ padding: "5px", border: ".5px solid black" }}
                      placeholder="Phone..."
                      defaultValue={props.phone}
                      onChange={(e) => onChange(e)}
                    />
                  </div>

              <div style={{display: "flex", justifyContent: "space-between"}}>
                  <div style={{width: "48%"}} className="form-group-custom">
                    <label className="editmodaltext" htmlFor="ticket">
                      <b>Ticket</b>
                    </label>
                    <input
                      id="ticket"
                      type="text"
                      name="tickettype"
                      className="form-control form-control-md"
                      style={{ padding: "5px", border: ".5px solid black" }}
                      placeholder="Ticket Type..."
                      defaultValue={props.tickettype}
                      onChange={(e) => onChange(e)}
                    />
                  </div>

                  <div style={{width: "48%"}} className="form-group-custom">
                    <label className="editmodaltext" htmlFor="ticket">
                      <b>Table/Row #</b>
                    </label>
                    <input
                      id="table_row_number"
                      type="text"
                      name="table_row_number"
                      className="form-control form-control-md"
                      style={{ padding: "5px", border: ".5px solid black" }}
                      placeholder="Table #..."
                      defaultValue={props.table_row_number}
                      onChange={(e) => onChange(e)}
                    />
                  </div>

                  </div>

                  <div className="form-group-custom">
                    <label className="editmodaltext" htmlFor="ticket">
                      <b>Seat #</b>
                    </label>
                    <input
                      id="seat_number"
                      type="text"
                      name="seat_number"
                      className="form-control form-control-md"
                      style={{ padding: "5px", border: ".5px solid black" }}
                      placeholder="Seat #..."
                      defaultValue={props.seat_number}
                      onChange={(e) => onChange(e)}
                    />
                  </div>

                  <div className="form-group-custom">
                    <label className="editmodaltext" htmlFor="ticket">
                      <b>Additional Details</b>
                    </label>
                    <textarea
                      id="guest_details"
                      type="text"
                      name="guest_details"
                      className="form-control form-control-md"
                      style={{ padding: "5px", border: ".5px solid black" }}
                      placeholder="Additional Details..."
                      defaultValue={props.guest_details}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                </div>
              </form>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <button
                  className="viewTicket"
                  onClick={() => setViewTicket(true)}
                  style={{ float: "left" }}
                >
                  <b>View Ticket</b>
                </button>
                <button
                  className="viewTicket"
                  onClick={() => guestprofile()}
                  style={{ float: "left" }}
                >
                  <b>View Profile</b>
                </button>
              </div>
            </div>
          ) : (
            <div>
              <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                    View Guest Ticket
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div id="divToPrint">
                    <GuestTicket guest={props} />
                  </div>
                  <div className="mb5">
                    <button
                      style={{ padding: 7 }}
                      className="viewTicketButton"
                      onClick={printDocument}
                    >
                      <b>Download Ticket</b>
                    </button>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="info" onClick={() => setViewTicket(false)}>
                    Go back to Editing
                  </Button>
                  <Button variant="danger" onClick={() => closeEditModal()}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onSubmit}>
            Submit
          </Button>
          <Button variant="danger" onClick={() => closeEditModal()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditGuestModal;
