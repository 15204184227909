import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { isEmpty } from "../../utils/Validation";
import "./Dashboard.css";
import * as action from "../../store/actions/index";
import Button from "react-bootstrap/Button";
import AddEvent from "./AddEvent";
import moment from "moment";
import EditEventModal from "./EditEventModal";
import DeleteEventMod from "./DeleteEventMod";
import CloneEventMod from "./CloneEventMod";

import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
// import DashboardList from "./DashboardLists/DashboardLists";
// import Fragment from "../../utils/Fragment";
// import { Alert, Grid } from "@mui/material";
// import { ToastContainer, toast } from "react-toastify";
// import { includes } from "lodash";

const Dashboard = (props) => {
  const [eventName, setEventName] = useState("");
  const [editEventModal, setEditEventModal] = useState(false);
  const [activeItemId, setActiveItemId] = useState(0);
  const [editedEvent, setEditedEvent] = useState([]);
  const [deleteEventModal, setDeleteEventModal] = useState(false);
  const [cloneEventModal, setCloneEventModal] = useState(false);

  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);

  useEffect(() => {
    reduxState.dashboard.switchEvent = false;
    
    dispatch(action.fetchDashboard());

    if (reduxState.dashboard.eventName === "") {
      // reduxState.dashboard.switchEvent = false
      // switchEvent = false
    } else {
      // reduxState.dashboard.switchEvent = true
      // switchEvent = true
    }

    //   this.props.clearDashmessage();
  }, []);

  const fetchTheDashboard = () => {
    dispatch(action.fetchDashboard());
  };

  const showEvent = (itemid) => {
    reduxState.dashboard.eventid = itemid;
    setEventName(itemid);
    reduxState.dashboard.eventName = itemid;
    reduxState.dashboard.switchEvent = true;
    dispatch(action.fetchDashboard());
    // switchEvent = true'
    // this.setState({well: true})
  };

  const deleteEvent = (itemid) => {
    setDeleteEventModal(true);
    setActiveItemId(itemid);
    // e.stopPropagation()
    // e.preventDefault()
  };

  const cloneEvent = (itemid) => {
    setCloneEventModal(true);
    setActiveItemId(itemid);
  };

  const editEvent = (itemid) => {
    setEditEventModal(true);
    setActiveItemId(itemid);
    console.log("itemid");
    console.log(itemid);
    setEditedEvent(
      reduxState.dashboard.dashboard.filter((d) => d._id === itemid)
    );
    // e.stopPropagation()
    // e.preventDefault()
  };

  let closeEditModal = () => setEditEventModal(false);
  let closeDeleteModal = () => setDeleteEventModal(false);
  let closeCloneModal = () => setCloneEventModal(false);


  let focusCountCell = 0
  let modalTabbable = document.querySelectorAll(`div[class='maincontent'] *[tab-index='0']`)

  let keyboardAccessibility = useCallback(() => {

    document.addEventListener("keydown", e => {
      try {
        // Keydown Events
        if (e.key === "ArrowRight" || (e.key === "Tab" && !e.shiftKey)) {
          modalTabbable[focusCountCell++].focus();
          e.preventDefault()
        }
        else if (e.key === "ArrowLeft" || (e.shiftKey && e.key === 'Tab')) {
          if (focusCountCell > 0) {
            modalTabbable[--focusCountCell].focus();
            e.preventDefault()
          }
        }
      } catch (e) {
        console.log(`accessibility keydown error ${e}`)
      }
    })
  })

  // keyboardAccessibility()

  let authRedirect = null;
  if (!reduxState.auth.isAuthenticated) {
    authRedirect = <Redirect to="/" />;
  }

  let events = (
    <div className="noevents" style={{ textAlign: "center", padding: 30 }}>
      <h2 style={{ marginLeft: 20 }} className="noevents">
        No Events Created or Accesible...
      </h2>
    </div>
  );

  if (!isEmpty(reduxState.dashboard.dashboard)) {
    try {
      events = <h2 className="display-4 None">Not Empty.</h2>;

     // Chapter Specific Access
    let userChaptersAssigned = reduxState.auth.user.chapter_volunteer.map(
      (arr) => arr.chapters_assigned
    );

    let allowedevents = [];

    if(reduxState.auth.user.role === "Owner Original") {
      allowedevents = reduxState.dashboard.dashboard;
    }
    else if(reduxState.auth.user.chapter_volunteer.filter((item)=>item.chapters_assigned === "All Events").length > 0){
      allowedevents = reduxState.dashboard.dashboard;
    }
    else{
    let filteredevents = reduxState.dashboard.dashboard.filter(
      (item) => item.chapter_event
    );

    let dashboardchapters = reduxState.dashboard.dashboard.filter(
      (item) => item.chapter_dashboard.map((items)=>items.chapter_dash_names).some(r=> userChaptersAssigned.includes(r)))
    // arr1.some(r=> arr2.includes(r))
    console.log("dashboardchapters")
    console.log(dashboardchapters)

    allowedevents = dashboardchapters

    allowedevents.push(
      reduxState.dashboard.dashboard.filter(
        (item) => item.chapter_dashboard.map((items)=>items.chapter_dash_names).some(r => r === "All Users"))[0]
    );

    allowedevents.push(
      filteredevents.filter((item) => item.chapter_event === "All Users")[0]
    );

    // Remove any undefined
    let undefinedfilter = allowedevents.filter((item)=>item !== undefined)
      allowedevents = undefinedfilter
    }

    console.log("prerender allowedevents")
    console.log(allowedevents)
 
      events = allowedevents.map((item, index) => (
        <div
          className="d-flex flex-row mb-3"
          key={index}
          style={{
            margin: 10,
            paddingLeft: 30,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <Link to={`/events/${item._id}`}>
            <Button
              id="eventlistbutton"
              variant="light"
              className="eventlistbutton"
              style={{
                paddingRight: 10,
                paddingLeft: 15,
                paddingBottom: 10,
                opacity: 0.8,
                boxShadow: "3px 3px 9px dimgray",
                border: ".9px solid gray",
                borderRadius: 10,
                minWidth: 280,
                fontFamily: "Verdana",
              }}
              onClick={() => showEvent(item._id)}
            >
              <div
                style={{}}
                onClick={(e) => {
                  reduxState.dashboard.eventid = item._id;
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {reduxState.auth.user.role === "Owner Original" ||
                reduxState.auth.user.role === "Owner" ||
                reduxState.auth.user.role === "Admin" ? (
                  <i
                    className="delete_event fas fa-remove fa-xl"
                    onClick={() => deleteEvent(item._id)}
                  ></i>
                ) : null}
              </div>

              <div style={{ marginRight: 50 }} className="eventname_label">
                <b>Event Name:</b> {item.name}
              </div>
              <div className="additionaldetails_label">
                <b>Location:</b>{" "}
                {item.location.length < 35
                  ? item.location
                  : item.location.slice(0, 20) + "..."}
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {/* Implementing Volunteer Permissions */}
                {reduxState.auth.user.role === "Owner Original" ||
                reduxState.auth.user.role === "Owner" ||
                reduxState.auth.user.role === "Admin" ||
                reduxState.auth.user.role === "Editor" ? (
                  <i
                    style={{ paddingTop: 12 }}
                    className="edit_event fas fa-pen fa-xl"
                    onClick={() => editEvent(item._id)}
                  ></i>
                ) : null}
              </div>
              {item.startdate == null ? (
                <div className="additionaldetails_label">
                  <b>Start Date:</b>
                </div>
              ) : (
                <div className="additionaldetails_label">
                  <b>Start Date:</b>{" "}
                  {moment(item.startdate).utc().format("MM/D/YY")}
                </div>
              )}
              {item.enddate == null ? (
                <div className="additionaldetails_label">
                  <b>End Date:</b>
                </div>
              ) : (
                <div className="additionaldetails_label">
                  <b>End Date:</b>{" "}
                  {moment(item.enddate).utc().format("MM/D/YY")}
                </div>
              )}

              {item.starttime === "" ? (
                <div className="additionaldetails_label">
                  <b>Event Time:</b>{" "}
                </div>
              ) : (
                <div>
                  {item.starttime === null ? (
                    <div className="additionaldetails_label">
                      <b>Event Time:</b>{" "}
                    </div>
                  ) : (
                    <div className="additionaldetails_label">
                      <b>Event Time:</b>{" "}
                      {moment(item.starttime, "HH:mm").format("hh:mm A")}
                    </div>
                  )}
                </div>
              )}

              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {/* Implementing Volunteer Permissions */}
                {reduxState.auth.user.role === "Owner Original" ||
                  reduxState.auth.user.role === "Owner" ||
                  reduxState.auth.user.role === "Admin" ||
                  reduxState.auth.user.role === "Editor" ? (
                  <i
                    style={{ paddingTop: 14 }}
                    className="edit_event fas fa-clone fa-xl"
                    onClick={() => cloneEvent(item._id)}
                  ></i>
                ) : null}
              </div>

              {item.timezone === "" ? (
                <div className="additionaldetails_label">
                  <b>Timezone:</b> {item.timezone}
                </div>
              ) : (
                <div className="additionaldetails_label">
                  <b>Timezone:</b>{" "}
                  {item.timezone.length < 35
                    ? item.timezone
                    : item.timezone.slice(0, 19) + "..."}
                </div>
              )}
              <div className="additionaldetails_label">
                <b>Chapter:</b> {(item.chapter_dashboard.map((items)=>items.chapter_dash_names)).join(", ")}
              </div>

            </Button>
          </Link>
        </div>
      ));
    } catch {
      console.log("redux map error");
    }
  }

  return (
    <div>
      <div>
        {/* <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        /> */}
        <DeleteEventMod
          show={deleteEventModal}
          onHide={closeDeleteModal}
          itemId={activeItemId}
        />

        <CloneEventMod
          show={cloneEventModal}
          onHide={closeCloneModal}
          itemId={activeItemId}
        />

        <EditEventModal
          show={editEventModal}
          onHide={closeEditModal}
          itemId={activeItemId}
          editedEvent={editedEvent}
        />
        {/* {reduxState.dashboard.dashMessage !== "" ? (
          <Grid container justifyContent="flex-end">
            <Alert
              className="float-right"
              style={{
                position: "fixed",
                zIndex: 1,
                marginTop: "1%",
                marginRight: "1%",
                border: ".1px solid gray",
              }}
              severity="success"
            >
              <div
                className="float-right"
                style={{
                  alignSelf: "flex-end",
                  textAlign: "center",
                }}
              >
                <b>{reduxState.dashboard.dashMessage}</b>
              </div>
            </Alert>
          </Grid>
        ) : null} */}

        {/* {reduxState.dashboard.dashMessageSuccess !== "" ? (
          <Grid container justifyContent="flex-end">
            <Alert
              className="float-right"
              style={{
                position: "fixed",
                zIndex: 1,
                marginTop: "1%",
                marginRight: "1%",
                border: ".1px solid gray",
              }}
              severity="success"
            >
              <div
                className="float-right"
                style={{
                  alignSelf: "flex-end",
                  textAlign: "center",
                }}
              >
                <b>{reduxState.dashboard.dashMessageSuccess}</b>
              </div>
            </Alert>
          </Grid>
        ) : null} */}

        {authRedirect}
        {/* {authRedirecet} */}

          <div>
            {/* {reduxState.auth.user.role === "user" || "admin" ? ( */}
            <div>
              <h1
                className="eventlist_titles"
                style={{
                  textAlign: "center",
                  marginTop: 35,
                  marginBottom: 15,
                }}
              >
                <b style={{ fontFamily: "Verdana, Geneva, Helvetica" }}>
                  Events Dashboard
                </b>
              </h1>
            </div>
            {/* ) : null} */}
            <hr></hr>
            {/* <div style={{ textAlign: "center" }}>
                <h1 className="eventlist_titles">
                  <b>Choose an Event</b>
                </h1>
                <br />
              </div> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {events}
            </div>

            <hr></hr>
            <div>
              <AddEvent fetchTheDashboard={fetchTheDashboard} />
              <br />
            </div>
          </div>
      </div>
    </div>
  );
};

// const mapStateToProps = (state, ownProps) => {
//   return {
//     dashboards: state.dashboard,
//     isAuthenticated: state.auth.isAuthenticated,
//     user: state.auth.user,
//   };
// };

export default Dashboard;
