import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import { apply, compose, lift, splitAt, zipObj } from "ramda";
import * as XLSX from "xlsx";
// import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
// import the progress bar
import StepProgressBar from "react-step-progress";
// import the stylesheet
import "react-step-progress/dist/index.css";
import "./ImportCsv.css";
// import Alert from "react-bootstrap/Alert";
import { Alert, Grid } from "@mui/material";
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import BarLoader from "react-spinners/BarLoader";
import * as actions from "../../../store/actions/index";
import moment from "moment";

const ImportCsv = (props) => {
  // Defining State for Importing Module
  const [mainheaders, setMainheaders] = useState([]);
  const [mainheadersMapped, setMainheadersMapped] = useState([]);
  const [importData, setImportData] = useState([]);
  const [import_row0, setImport_row0] = useState([]);
  const [import_row1, setImport_row1] = useState([]);
  const [import_row2, setImport_row2] = useState([]);
  const [import_row3, setImport_row3] = useState([]);
  const [import_row4, setImport_row4] = useState([]);
  const [import_row5, setImport_row5] = useState([]);
  const [import_row6, setImport_row6] = useState([]);
  const [import_row7, setImport_row7] = useState([]);

  const [importVerified, setImportVerified] = useState(true);

  const [importCount, setImportCount] = useState(0);
  const [addedImportCount, setAddedImportCount] = useState(0);

  const [dropdownHeaderOptions, setDropdownHeaderOptions] = useState([]);
  const [dropdownHeaderOptionsVerify, setDropdownHeaderOptionsVerify] =
    useState([]);

  const [duplicatesMapped, setDuplicatesMapped] = useState(false);

  const [new_guests, setNew_guests] = useState(0);
  const [existing_guests, setExisting_guests] = useState(0);
  const [error_guests, setError_guests] = useState(0);

  const [nonexisting_ids_state, setNonexisting_ids_state] = useState([]);

  // State to Load each Step of the Import
  const [loadstep2, setLoadstep2] = useState(true);
  const [loadstep3, setLoadstep3] = useState(false);
  const [loadstep4, setLoadstep4] = useState(false);
  const [loadstep5, setLoadstep5] = useState(false);
  const [loadstep6, setLoadstep6] = useState(false);

  const [guestbar_eventname, setGuestbar_eventname] = useState("");
  const [guestbar_createddate, setGuestbar_createddate] = useState("");

  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();

  const keys = [
    "listItem",
    "email",
    "phone",
    "tickettype",
    "guest_details",
    "organization",
    "table_row_number",
    "seat_number",
  ];

  useEffect(() => {
    dispatch(actions.import_error_off());
    dispatch(actions.import_loading_off());

    console.log("importcsv reduxState");
    console.log(reduxState.dashboard);
    setGuestbar_eventname(
      reduxState.dashboard.dashboard.filter(
        (item) => item._id.toString() === props.eventChosen
      )[0].name
    );
    setGuestbar_createddate(
      reduxState.dashboard.dashboard.filter(
        (item) => item._id.toString() === props.eventChosen
      )[0].createdAt
    );
    // axios
    // .get("/api/lists/importaccountids")
    // .then((res) => {
    //   // setChapters_array(res.data.user.chapter)
    //   console.log("axios responsity");
    //   console.log(res.data);
    // })
    // console.log("props.match.params.eventId");
  }, []);

  let authRedirect = null;
  if (!reduxState.auth.isAuthenticated) {
    authRedirect = <Redirect to="/" />;
  }

  const handleExcel = async (e) => {
    console.log("handleexcelrun");
    console.log("reading input file:");
    const file = e.target.files[0];
    console.log("file grab");
    console.log(file);

    const data = await file.arrayBuffer();

    console.log("data conversion arrayBuffer");
    console.log(data);

    const workbook = XLSX.read(data);

    console.log("workbook");
    console.log(workbook);

    // Here we try to caluclate more than one sheet to throw an error
    console.log("workbook.Sheets.length");
    console.log(workbook.SheetNames.length);

    // Throw an error if sheet numbers are more than one.

    if (workbook.SheetNames.length > 1) {
      console.log("Error! Only One Sheet allowed.");
      toast.error(
        <div style={{ fontSize: "16px" }}>
          <b>Error. Only One Sheet Allowed! Multiple Sheets Detected.</b>
        </div>,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } else {
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      console.log("worksheet");
      console.log(worksheet);

      console.log("worksheet");
      console.log(worksheet);

      const headers_file_pull = XLSX.utils.sheet_to_json(worksheet);
      const headers = headers_file_pull[0];

      console.log("headers_file_pull!");
      console.log(headers_file_pull);
      console.log("headers_file_pull headers!!!");
      console.log(headers);

      let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[[0]], {
        header: 1,
        defval: "",
      });

      console.log("rowObject");
      console.log(rowObject);

      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
      });

      console.log("number of rows");
      console.log(jsonData.length);


      if (jsonData.length > 1001) {
        console.log("Error! Only 1000 Rows Allowed. Over 1,000 Row Detected.");
        toast.error(
          <div style={{ fontSize: "16px" }}>
            <b>Error! Only 1000 Rows Allowed. Over 1,000 Row Detected.</b>
          </div>,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {

        if (reduxState.auth.user.toptier_subscription == true
          || reduxState.auth.user.subscription == true || (reduxState.dashboard.dashboard.filter(
            (item) => item._id.toString() === props.eventChosen
          )[0].lists.length + jsonData.length) < 25){

            console.log("attempted")
            console.log(reduxState.dashboard.dashboard.filter(
              (item) => item._id.toString() === props.eventChosen
            )[0].lists.length + jsonData.length)

        let keys = jsonData[0];
        console.log("keys");
        console.log(keys);

        setMainheaders(keys);
        setMainheadersMapped(keys);

        // let headerdropdowns = [];

        // for (var i = 0; i < keys.length; i++) {
        //   headerdropdowns.push({
        //     value: keys[i],
        //     label: keys[i],
        //   });
        // }

        let builtin_fields = [
          "Do Not Import",
          "Account ID",
          "Name",
          "Email",
          "Phone",
          "Ticket Type",
          "Guest Details",
          "Organization",
          "Table #",
          "Seat #",
        ];

        let headerdropdownsformatted = [];

        for (var i = 0; i < builtin_fields.length; i++) {
          headerdropdownsformatted.push({
            value: builtin_fields[i],
            label: builtin_fields[i],
          });
        }

        setDropdownHeaderOptions(headerdropdownsformatted);

        console.log("jsonData");
        console.log(jsonData);

        // Seperating 1 row from imported Data.
        // Using new array, because using shift will delete it from JsonData variable
        // And we still need jsonData untouched to pull keys from the first row.
        let importData = [...jsonData];
        importData.shift();

        // console.log("importData");
        // console.log(importData);
        // console.log(importData[0]);
        // console.log(importData[0]?.length);

        if (importData != null) {
          setImportData(importData);
        }

        if (importData[0] != null) {
          setImport_row0(importData[0]);
        }

        if (importData[1] != null) {
          setImport_row1(importData[1]);
        }

        if (importData[2] != null) {
          setImport_row2(importData[2]);
        }

        if (importData[3] != null) {
          setImport_row3(importData[3]);
        }

        if (importData[4] != null) {
          setImport_row4(importData[4]);
        }

        if (importData[5] != null) {
          setImport_row5(importData[5]);
        }

        if (importData[6] != null) {
          setImport_row6(importData[6]);
        }

        if (importData[7] != null) {
          setImport_row7(importData[7]);
        }

        // add api headers
        // importData.unshift(keys)

        console.log("importData2 unshift");
        console.log(importData);

        // convert arrays to objects
        const formatedResult = compose(
          apply(lift(zipObj)),
          splitAt(1)
        )(importData);

        console.log("formatted results");
        console.log(importData);

        let importMatrix = [];

        for (var k = 0; k < keys.length; k++) {
          importMatrix.push({
            value: "Blank",
            label: keys[k],
          });
        }
        setDropdownHeaderOptionsVerify(importMatrix);

        console.log("importMatrix upload");
        console.log(importMatrix);

        setLoadstep2(false);
        setLoadstep3(true);
      }

      else {
        console.log("Error! Only 25 Guests allowed without a Subscription. You are attempting to import more.");
        toast.error(
          <div style={{ fontSize: "16px" }}>
            <b>Error! Only 25 Guests allowed without a Subscription.</b>
            <b> You are attempting to import more than 25 Guests.</b>
          </div>,
          {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }







    }
  }


  };

  const handleFile = (event) => {
    const file = event.target.files[0];
    const keys = keys;
    console.log("keys");
    console.log(keys);
    const handleImportPost = props.handleImportPost;
    const onError = props.onError;

    Papa.parse(file, {
      skipEmptyLines: true,
      error: function (err, file, inputElem, reason) {
        onError({ err, file, inputElem, reason });
      },
      complete: function (results) {
        console.log(results);
        const data = results.data;

        console.log("keys1");
        console.log(keys);

        console.log("data1");
        console.log(data);
        // remove display headers
        data.shift();

        console.log("data1 shift");
        console.log(data);

        // add api headers
        data.unshift(keys);

        console.log("data2 unshift");
        console.log(data);

        // convert arrays to objects
        const formatedResult = compose(apply(lift(zipObj)), splitAt(1))(data);

        console.log("formatedResult");
        console.log(formatedResult);

        // send result to state
        // handleImportPost(formatedResult)
      },
    });
  };

  const handleImportMapper = (e, header) => {
    console.log("Changed");
    console.log(header);
    console.log(e.value);

    console.log("mainheaders");
    console.log(mainheaders);
    console.log(mainheaders.length);

    setImportCount(mainheaders.length);
    // setAddedImportCount();

    console.log("import count");
    console.log(importCount);

    console.log("dropdownHeaderOptionsVerify");
    console.log(dropdownHeaderOptionsVerify);

    // Here Let's see if all columns are mapped in a scenario of a dynamic number of columns
    let verificationmap = dropdownHeaderOptionsVerify.filter(
      (item) => item.label !== header
    );

    verificationmap.push({ value: "Complete", label: header, mapped: e.value });
    console.log("verificationmap");
    console.log(verificationmap);
    setDropdownHeaderOptionsVerify(verificationmap);
    let verifiedarray = verificationmap.map((item) => item.value);
    let allverified = verifiedarray.every((v) => v === verifiedarray[0]);

    // Here Let's see if there are any duplicates in Event Sensei Mapped Values
    let allmapped = verificationmap.map((item) => item.mapped);

    let allmapped_wo_donotimport = allmapped.filter(
      (item) => item != "Do Not Import"
    );

    console.log("allmapped_wo_donotimport");
    console.log(allmapped_wo_donotimport);

    let truthy = 0;
    let falsey = 0;
    for (var i = 0; i < allmapped_wo_donotimport.length; i++) {
      for (var j = i + 1; j < allmapped_wo_donotimport.length; j++) {
        if (
          allmapped_wo_donotimport[i] == allmapped_wo_donotimport[j] &&
          allmapped_wo_donotimport[i] != undefined &&
          allmapped_wo_donotimport[j] != undefined
        ) {
          console.log("dup true");
          ++truthy;
        } else {
          console.log("dup false");
          ++falsey;
        }
      }
    }

    let duplicates_mapped_check = false;

    if (truthy > 0) {
      setDuplicatesMapped(true);
      duplicates_mapped_check = true;
    } else {
      setDuplicatesMapped(false);
      duplicates_mapped_check = false;
    }

    console.log("truthy");
    console.log(truthy);
    console.log("falsey");
    console.log(falsey);

    console.log("reverse all mapped");
    console.log(allmapped);

    // If all fields are mapped and there are no duplicates
    if (allverified === true && duplicates_mapped_check == false) {
      setImportVerified(false);
    } else {
      setImportVerified(true);
      console.log("still false");
    }

    console.log("mainheaders");
    console.log(mainheaders);

    let newheader = [...mainheadersMapped];

    let indexfound = mainheaders.indexOf(header);
    console.log("indexfound");
    console.log(indexfound);

    // Conversion of Proper Fields shown to the built-in values

    let converted = "";
    if (e.value === "Do Not Import") {
      converted = "do_not_import";
    }
    if (e.value === "Account ID") {
      converted = "account_id";
    }
    if (e.value === "Name") {
      converted = "listItem";
    }
    if (e.value === "Email") {
      converted = "email";
    }
    if (e.value === "Phone") {
      converted = "phone";
    }
    if (e.value === "Ticket Type") {
      converted = "tickettype";
    }
    if (e.value === "Guest Details") {
      converted = "guest_details";
    }
    if (e.value === "Organization") {
      converted = "organization";
    }
    if (e.value === "Table #") {
      converted = "table_row_number";
    }
    if (e.value === "Seat #") {
      converted = "seat_number";
    }

    newheader[indexfound] = converted;

    setMainheadersMapped(newheader);

    console.log("newheader");
    console.log(newheader);

    console.log("mainheadersMapped");
    console.log(mainheadersMapped);
  };

  const previewImport = () => {
    let previewData = [...importData];
    console.log("previewData");
    console.log(previewData);

    console.log("mainheadersmapped");
    console.log(mainheadersMapped);
    previewData.unshift(mainheadersMapped);

    // convert arrays to objects
    const formatedResult = compose(
      apply(lift(zipObj)),
      splitAt(1)
    )(previewData);

    console.log("previewData");
    console.log(previewData);

    console.log("formatedResult prepreview");
    console.log(formatedResult);

    let existing_guests = [];

    if (
      formatedResult[0].account_id != null ||
      formatedResult[0].account_id != undefined
    ) {
      existing_guests = formatedResult.filter((item) => item.account_id != "");
    }

    let new_guests = [];
    if (
      formatedResult[0].account_id != null ||
      formatedResult[0].account_id != undefined
    ) {
      new_guests = formatedResult.filter((item) => item.account_id == "");
    } else {
      new_guests = formatedResult;
    }

    console.log("new_guests - preview");
    console.log(new_guests);
    console.log("existing_guests");
    console.log(existing_guests);

    console.log("accountids imported");
    let accountids_imported = existing_guests.map((item) => item.account_id);
    console.log(accountids_imported);

    axios
      .get("/api/lists/importaccountids")
      .then((res) => {
        // setChapters_array(res.data.user.chapter)
        console.log("all accountids db");
        console.log(res.data.all_event_accountids);
        let existing_accountids = [];
        existing_accountids = res.data.all_event_accountids;

        let nonexisting_ids = [];
        let nonexisting_ids_index = [];

        for (var i = 0; i < accountids_imported.length; i++) {
          if (!existing_accountids.includes(accountids_imported[i])) {
            nonexisting_ids.push(accountids_imported[i]);
            nonexisting_ids_index.push(
              accountids_imported.indexOf(accountids_imported[i])
            );
          }
        }

        console.log("nonexisting_ids");
        console.log(nonexisting_ids);
        console.log("nonexisting_ids_index");
        console.log(nonexisting_ids_index);

        setExisting_guests(existing_guests.length - nonexisting_ids.length);
        setNew_guests(new_guests.length);
        setError_guests(nonexisting_ids.length);

        console.log("Prenonexisting_users earth");
        let nonexisting_users = [];

        for (var j = 0; j < nonexisting_ids.length; j++) {
          nonexisting_users.push({
            nonexisting_state: nonexisting_ids[j],
            nonexisting_index: nonexisting_ids_index[j],
          });
        }

        console.log("nonexisting_users earth");
        console.log(nonexisting_users);

        setNonexisting_ids_state(nonexisting_users);
      })
      .catch((err) => {
        console.log(err.response);
      });

    setLoadstep4(false);
    setLoadstep5(true);
  };

  const completeImport = (e) => {
    console.log("complete import running");
    console.log("mainheadersMapped");
    console.log(mainheadersMapped);

    let importingdata = [...importData];

    // add api headers
    importingdata.unshift(mainheadersMapped);

    console.log("data2 unshift");
    console.log(importingdata);

    // convert arrays to objects
    const formatedResult = compose(
      apply(lift(zipObj)),
      splitAt(1)
    )(importingdata);

    console.log("formatedResult imported");
    console.log(formatedResult);

    console.log("checkerrrrr");
    console.log(formatedResult[0].account_id);

    let existing_guests = [];

    if (
      formatedResult[0].account_id != null ||
      formatedResult[0].account_id != undefined
    ) {
      existing_guests = formatedResult.filter((item) => item.account_id != "");
    }

    let new_guests = [];
    if (
      formatedResult[0].account_id != null ||
      formatedResult[0].account_id != undefined
    ) {
      new_guests = formatedResult.filter((item) => item.account_id == "");
    } else {
      new_guests = formatedResult;
    }

    console.log("new_guests_import");
    console.log(new_guests);
    console.log(new_guests.length);
    console.log("existing_guests_import");
    console.log(existing_guests);
    console.log(existing_guests.length);

    // Method to Add Users without Account Ids

    if (new_guests.length > 0) {
      props.handleImportPost(new_guests);
      console.log("new guests posted");
    }

    // Method to Update Users with Account Ids
    if (existing_guests.length > 0) {
      props.handleImportUpdate(existing_guests);
      console.log("existing guests updated");
    }

    setLoadstep5(false);
    setLoadstep6(true);
  };

  const import_spreadsheet = (
    <div>
      <div style={{ paddingLeft: 40, paddingBottom: 30, marginTop: 20 }}>
        <div style={{ fontSize: 18, fontFamily: "Verdana" }}>
          <b style={{ fontFamily: "Verdana" }}>Step 2:
          </b> Upload a spreadsheet to import data. (CSV or Excel)
        </div>
      </div>

      {/* <input type="file" onClick={handleExcel}/> */}
      <div style={{ marginBottom: 20, marginLeft: 40 }}>
        {props.render(handleExcel)}
      </div>
    </div>
  );

  const initialpreview = (
    <div>
      <div style={{ paddingLeft: 40, paddingTop: 20, paddingBottom: 20 }}>
        <div style={{ fontSize: 18, fontFamily: "Verdana" }}>
          <b style={{ fontFamily: "Verdana" }}>Step 3: </b>
          <span>Preview the data that you're importing</span>
          <span style={{ display: "flex", fontSize: 15 }}>
            (Only the first 8 rows are displayed)
          </span>
        </div>
      </div>
      <table
        className="prTable"
        style={{
          marginBottom: 20,
          border: "1px solid darkgray",
          marginLeft: 20,
        }}
      >
        {/* <thead style={{backgroundColor: "#F8F8F8"}}> */}
        <thead>
          <tr className="prTableHeader">
            {mainheaders.map((header) => (
              <th
                key={header}
                style={{
                  minWidth: 80,
                  paddingRight: 10,
                  // color: "#484848",
                  color: "#404040",
                  background: "#F7FAFC",
                  paddingTop: 17,
                  paddingLeft: 5,
                  fontFamily: "Verdana",
                  border: ".9px solid darkgray",
                }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{}}>
          {import_row0 != null ? (
            <tr className="prTableHeader">
              {import_row0.map((item, index) => (
                <td
                  key={item}
                  style={{
                    minWidth: 80,
                    paddingRight: 10,
                    // color: "#484848",
                    color: "#404040",
                    background: "white",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ) : null}
          {import_row1 != null ? (
            <tr className="prTableHeader">
              {import_row1.map((item, index) => (
                <td
                  key={item}
                  style={{
                    minWidth: 80,
                    paddingRight: 10,
                    // color: "#484848",
                    color: "#404040",
                    background: "white",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ) : null}
          {import_row2 != null ? (
            <tr className="prTableHeader">
              {import_row2.map((item, index) => (
                <td
                  key={item}
                  style={{
                    minWidth: 80,
                    paddingRight: 10,
                    // color: "#484848",
                    color: "#404040",
                    background: "white",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ) : null}
          {import_row3 != null ? (
            <tr className="prTableHeader">
              {import_row3.map((item, index) => (
                <td
                  key={item}
                  style={{
                    minWidth: 80,
                    paddingRight: 10,
                    // color: "#484848",
                    color: "#404040",
                    background: "white",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ) : null}
          {import_row4 != null ? (
            <tr className="prTableHeader">
              {import_row4.map((item, index) => (
                <td
                  key={item}
                  style={{
                    minWidth: 80,
                    paddingRight: 10,
                    // color: "#484848",
                    color: "#404040",
                    background: "white",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ) : null}
          {import_row5 != null ? (
            <tr className="prTableHeader">
              {import_row5.map((item, index) => (
                <td
                  key={item}
                  style={{
                    minWidth: 80,
                    paddingRight: 10,
                    // color: "#484848",
                    color: "#404040",
                    background: "white",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ) : null}
          {import_row6 != null ? (
            <tr className="prTableHeader">
              {import_row6.map((item, index) => (
                <td
                  key={item}
                  style={{
                    minWidth: 80,
                    paddingRight: 10,
                    // color: "#484848",
                    color: "#404040",
                    background: "white",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ) : null}
          {import_row7 != null ? (
            <tr className="prTableHeader">
              {import_row7.map((item, index) => (
                <td
                  key={item}
                  style={{
                    minWidth: 80,
                    paddingRight: 10,
                    // color: "#484848",
                    color: "#404040",
                    background: "white",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ) : null}
        </tbody>
      </table>

      <Button
        className="addguestbutton"
        style={{
          height: 40,
          borderRadius: 5,
          fontWeight: "bold",
          marginBottom: 40,
          width: 300,
          fontSize: 14,
          marginLeft: 40,
        }}
        variant="info"
        onClick={() => {
          setLoadstep3(false);
          setLoadstep4(true);
        }}
      >
        <span style={{ fontFamily: "Verdana" }}>
          Proceed to Mapping Columns
        </span>
      </Button>
    </div>
  );

  const mappedcolumns = (
    <div>
      <div style={{ paddingLeft: 40, paddingTop: 20, paddingBottom: 20 }}>
        <div style={{ fontSize: 18, fontFamily: "Verdana" }}>
          <b style={{ fontFamily: "Verdana" }}>Step 4: </b>
          <span>
            Map the columns in your spreadsheet to the application fields
          </span>
        </div>
      </div>
      <table
        className="prTable"
        style={{
          marginBottom: 20,
          border: "1px solid darkgray",
          marginLeft: 20,
        }}
      >
        {/* <thead style={{backgroundColor: "#F8F8F8"}}> */}
        <thead>
          <tr className="prTableHeader">
            {mainheaders.map((header) => (
              <th
                key={header}
                style={{
                  minWidth: 80,
                  paddingRight: 10,
                  // color: "#484848",
                  color: "#404040",
                  background: "#F7FAFC",
                  paddingTop: 17,
                  paddingLeft: 5,
                  fontFamily: "Verdana",
                  border: ".9px solid darkgray",
                  fontSize: 13,
                }}
              >
                <span> {header.length < 13 ? header : header.slice(0, 15) + "..."}</span>
                <i
                  style={{ marginLeft: 5 }}
                  className="fas fa-arrow-right fa-sm"
                ></i>
                <Select
                  // defaultValue={dropdownHeaderOptions.filter((item)=> item.value == header)}
                  onChange={(e) => handleImportMapper(e, header)}
                  options={dropdownHeaderOptions}
                  className="reactselectstyle"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      // borderColor: state.isFocused ? 'grey' : 'red',
                      borderColor: "gray",
                      fontSize: 12,
                      height: 0,
                      width: 163,
                      marginTop: 2,
                      marginBottom: 7,
                    }),
                  }}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{}}>
          {import_row0 != null ? (
            <tr className="prTableHeader">
              {import_row0.map((item, index) => (
                <td
                  key={item}
                  style={{
                    minWidth: 80,
                    paddingRight: 10,
                    // color: "#484848",
                    color: "#404040",
                    background: "white",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ) : null}
          {import_row1 != null ? (
            <tr className="prTableHeader">
              {import_row1.map((item, index) => (
                <td
                  key={item}
                  style={{
                    minWidth: 80,
                    paddingRight: 10,
                    // color: "#484848",
                    color: "#404040",
                    background: "white",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ) : null}
          {import_row2 != null ? (
            <tr className="prTableHeader">
              {import_row2.map((item, index) => (
                <td
                  key={item}
                  style={{
                    minWidth: 80,
                    paddingRight: 10,
                    // color: "#484848",
                    color: "#404040",
                    background: "white",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ) : null}
          {import_row3 != null ? (
            <tr className="prTableHeader">
              {import_row3.map((item, index) => (
                <td
                  key={item}
                  style={{
                    minWidth: 80,
                    paddingRight: 10,
                    // color: "#484848",
                    color: "#404040",
                    background: "white",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ) : null}
          {import_row4 != null ? (
            <tr className="prTableHeader">
              {import_row4.map((item, index) => (
                <td
                  key={item}
                  style={{
                    minWidth: 80,
                    paddingRight: 10,
                    // color: "#484848",
                    color: "#404040",
                    background: "white",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ) : null}
          {import_row5 != null ? (
            <tr className="prTableHeader">
              {import_row5.map((item, index) => (
                <td
                  key={item}
                  style={{
                    minWidth: 80,
                    paddingRight: 10,
                    // color: "#484848",
                    color: "#404040",
                    background: "white",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ) : null}
          {import_row6 != null ? (
            <tr className="prTableHeader">
              {import_row6.map((item, index) => (
                <td
                  key={item}
                  style={{
                    minWidth: 80,
                    paddingRight: 10,
                    // color: "#484848",
                    color: "#404040",
                    background: "white",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ) : null}
          {import_row7 != null ? (
            <tr className="prTableHeader">
              {import_row7.map((item, index) => (
                <td
                  key={item}
                  style={{
                    minWidth: 80,
                    paddingRight: 10,
                    // color: "#484848",
                    color: "#404040",
                    background: "white",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {item}
                </td>
              ))}
            </tr>
          ) : null}
        </tbody>
      </table>

      <Button
        className="addguestbutton"
        disabled={importVerified}
        style={{
          marginLeft: 40,
          height: 40,
          borderRadius: 5,
          fontWeight: "bold",
          marginBottom: 40,
          width: 300,
          fontSize: 14,
        }}
        variant="info"
        onClick={previewImport}
      >
        <span style={{ fontFamily: "Verdana" }}>Preview Import and Errors</span>
      </Button>
    </div>
  );

  const import_preview = (
    <div>
      <div style={{ paddingLeft: 40, paddingTop: 20, paddingBottom: 20 }}>
        <div style={{ fontSize: 18, fontFamily: "Verdana" }}>
          <b style={{ fontFamily: "Verdana" }}>Step 5: </b>
          <span>Preview Import and Errors</span>
        </div>
      </div>
      <div style={{ marginLeft: 40 }}>
        <div style={{ display: "flex" }}>
          <label>
    <span style={{fontFamily:"Verdana", fontSize: 15 }}><b style={{fontFamily:"Verdana", fontSize: 15 }}>Existing Guests: </b>{existing_guests}</span>
           </label>
        </div>
        <div style={{ display: "flex" }}>
          <label>
    <span style={{ fontSize: 15, fontFamily:"Verdana", }}><b style={{fontFamily:"Verdana", fontSize: 15 }}>New Guests: </b>{new_guests}</span>
           </label>
        </div>
        <div style={{ display: "flex" }}>
          <label>
    <span style={{fontFamily:"Verdana", fontSize: 15 }}><b style={{fontFamily:"Verdana", fontSize: 15 }}>Error Guests: </b>{error_guests}</span>
           </label>
        </div>
      </div>
      {/* {nonexisting_ids_state?
  <div>
    {nonexisting_ids_state.map((item, index)=>
  <div>
    {item[index]}
  </div>
  )}
  </div>
: null} */}
      {nonexisting_ids_state.length > 0 ?
      <div style={{ marginLeft: 20, marginTop: 15 }}>
      <div style={{
        fontWeight: "bold",
        fontSize: 15,
        fontFamily:"Verdana",
        marginLeft: 15,
        color: "#A51C30",
        marginBottom: 15
      }}>
        You must remove these errors from your spreadsheet before importing.
      </div>
        {nonexisting_ids_state.map((item, index) => (
          <div
            key={item}
            style={{
              minWidth: 80,
              paddingRight: 10,
              color: "#404040",
              background: "white",
              paddingTop: 17,
              paddingLeft: 5,
              fontFamily: "Verdana",
              width: "500px",
            }}
          >
            <Alert
                // className="float-right"
                style={{
                  // position: "fixed",
                  zIndex: 1,
                  border: ".1px solid gray",
                  fontSize: 14,
                  marginTop: -12
                }}
                severity="error"
              >
                Account ID <b>{item.nonexisting_state}</b> does not exist in the system
              at <b>Row {item.nonexisting_index + 1}</b>
                </Alert>
          </div>
        ))}
              <br />
      <Link to={`/import/`}>
      <Button
        className="addguestbutton"
        style={{
          height: 40,
          borderRadius: 5,
          fontWeight: "bold",
          marginBottom: 40,
          width: 300,
          fontSize: 14,
          marginLeft: 20,
        }}
        variant="info"
      >
        <span style={{ fontFamily: "Verdana" }}>
          Back to File Upload
        </span>
      </Button>
      </Link>
      </div>
      : 

      <Button
        className="addguestbutton"
        style={{
          height: 40,
          borderRadius: 5,
          fontWeight: "bold",
          marginBottom: 40,
          width: 300,
          fontSize: 14,
          marginLeft: 40,
          marginTop: 20
        }}
        variant="info"
        onClick={completeImport}
      >
        <span style={{ fontFamily: "Verdana" }}>Start Import</span>
      </Button>
       }
    </div>
  );

  const import_results = (
    <div>
      {/* Importing Spinner */}
      {reduxState.dashboard.importing_complete_loading ||
      reduxState.dashboard.importing_complete_updating ? (
        <div>
          <hr></hr>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
              marginTop: "13%",
            }}
          >
            <BarLoader
              color={"#073980"}
              // loading={loading}
              size={300}
              width={400}
              thickness={100}
              height={20}
              aria-label="Loading Spinner"
              data-testid="loader"
              speedMultiplier={1}
              margin={0}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h2>Importing Data...</h2>
          </div>
        </div>
      ) : (
        <div style={{marginTop: 40, marginLeft: 40, marginBottom: 30 }}>
          <h4
            style={{
              fontWeight: "bold",
              fontFamily: "Verdana",
              marginBottom: 20,
              fontSize: 21,
              color: "#303030",
            }}
          >
            Import Results
          </h4>
          <div style={{ display: "flex" }}>
            <label>
              <b style={{fontFamily:"Verdana", fontSize: 15 }}>Guests Updated: </b>
              <span style={{fontFamily:"Verdana", fontSize: 15 }}>{existing_guests}</span>
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <label>
              <b style={{fontFamily:"Verdana", fontSize: 15 }}>Guests Added: </b>
              <span style={{fontFamily:"Verdana", fontSize: 15 }}>{new_guests}</span>
            </label>
          </div>
          <div style={{ display: "flex" }}>
            <label>
              <b style={{fontFamily:"Verdana", fontSize: 15 }}>Current Number Guests: </b>
              <span style={{fontFamily:"Verdana", fontSize: 15 }}>
                {
                  reduxState.dashboard.dashboard.filter(
                    (item) => item._id.toString() === props.eventChosen
                  )[0].lists.length
                }
              </span>
            </label>
          </div>
        </div>
      )}
    </div>
  );

  const error_import_page = (
    <div>
      <div style={{ marginLeft: 30, marginBottom: 35 }}>
        <h1
          style={{
            color: "#404040",
            fontWeight: "bold",
            fontSize: 20,
            fontFamily: "Verdana",
            marginTop: "2%",
            marginBottom: 20,
          }}
        >
          An unexpected Error has occurred with the Import
        </h1>
        <div style={{ width: "70%" }}>
          <h3 style={{ fontSize: 15, fontFamily: "Verdana", marginTop: "3%" }}>
            Please check your spreadsheet for any formulas that aren't removed
            or possible errors and try again.
          </h3>
          <h3 style={{ fontSize: 15, fontFamily: "Verdana", marginTop: "3%" }}>
            Reach out to customer support with error code
            <b style={{ color: "darkred" }}> 839 - Import Error.</b>
          </h3>
        </div>
      </div>

      <div style={{ marginTop: "2%", marginLeft: 35 }}>
        <Button
          id="eventlist"
          variant="light"
          className="eventlist"
          onClick={() => window.location.reload()}
          style={{
            boxShadow: "0px 3px 6px #707070",
            opacity: 0.8,
            padding: 9,
            border: "1px solid #707070",
            borderRadius: 5,
            minWidth: 260,
            textDecoration: "none",
          }}
        >
          <div
            style={{
              textAlign: "center",
              textDecoration: "none",
              color: "#101010",
            }}
            // to={`/import/${props.eventChosen}`}
          >
            <i
              style={{
                color: "#101010",
                textDecoration: "none",
                fontSize: "20px",
                marginRight: 12,
              }}
              className="fas fa-refresh fa-sm"
            ></i>
            <span
              style={{
                color: "#101010",
                fontSize: 15,
                fontWeight: "bold",
                marginTop: -10,
              }}
            >
              Reload Import Page
            </span>
          </div>
        </Button>
      </div>
    </div>
  );

  const event_info_bar = (
    <div
      style={{
        display: "flex",
        marginLeft: 30,
        // justifyContent: "center",
        marginBottom: 5,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "whitesmoke",
          width: "600px",
          padding: 8,
          border: "1px solid #909090",
          borderRadius: 2,
        }}
      >
        <div>
          <div>
            <b>Event Name:</b>{" "}
            <span style={{ fontSize: 14, fontFamily: "Verdana" }}>
              {guestbar_eventname}
            </span>
          </div>
          <div>
            <span style={{ fontSize: 12, fontFamily: "Verdana" }}>
              <b>Created:</b>{" "}
              {moment(guestbar_createddate).format("MM/D/YY, h:mm A")}{" "}
            </span>
          </div>
        </div>
        <div className="ListSum">
          <h5 style={{ marginLeft: 12 }} className="">
            {
              reduxState.dashboard.dashboard.filter(
                (item) => item._id.toString() === props.eventChosen
              )[0].lists.length
            }
          </h5>
          <span style={{ fontSize: 12, marginRight: 10 }} className="card-text">
            {reduxState.dashboard.dashboard.filter(
              (item) => item._id.toString() === props.eventChosen
            )[0].lists.length <= 1
              ? "Guest"
              : "Guests"}
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div
      style={{
        marginTop: 18,
      }}
    >
      {!reduxState.dashboard.importing_error ? (
        <div>
          {event_info_bar}
          {authRedirect}
          {/* Error Alerts by Toast */}
          <ToastContainer
            position="top-left"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {loadstep2 ? <div>{import_spreadsheet}</div> : null}
          {loadstep3 ? <div>{initialpreview}</div> : null}
          {duplicatesMapped ? (
            <div
              style={{
                display: "flex",
                marginLeft: 30,
                marginBottom: 10,
              }}
            >
              <Alert
                // className="float-right"
                style={{
                  // position: "fixed",
                  zIndex: 1,
                  border: ".1px solid gray",
                }}
                severity="error"
              >
                <div
                  style={{ fontSize: 14, marginTop: -4, fontFamily: "Verdana" }}
                >
                  Duplicate Import Fields are Mapped.
                </div>
              </Alert>
            </div>
          ) : null}

          {loadstep4 ? <div>{mappedcolumns}</div> : null}

          {loadstep5 ? <div>{import_preview}</div> : null}

          {loadstep6 ? <div>{import_results}</div> : null}
        </div>
      ) : (
        <div>{error_import_page}</div>
      )}
    </div>
  );
};

export default ImportCsv;