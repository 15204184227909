import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as actions from "../../store/actions/index";
// import moment from "moment";
import DeleteMailSettings from "./DeleteMailSettings";
import Select from "react-select";
import axios from "axios";
import { Alert, Grid } from "@mui/material";
import BarLoader from "react-spinners/BarLoader";
import './Profile.css'

const ApiAccess = (props) => {
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
  //   const history = useHistory();

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deletingId, setDeletingId] = useState("");

  const [socketerrorCode, SetSocketerrorCode] = useState("");
  const [socketerrorCommand, SetSocketerrorCommand] = useState("");
  const [socketerrorReponse, SetSocketerrorReponse] = useState("");
  const [socketerrorResponseCode, SetSocketerrorResponseCode] = useState("");
  const [socketerrorReason, SetSocketerrorReason] = useState("");

  const [startConnection, setStartConnection] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [succesfulConnection, setSuccesfulConnection] = useState(false);

  const [secure_default, setSecure_default] = useState([
    {
      label: "True",
      value: "True",
    },
  ]);

  const [formData, setFormData] = useState({
    server_mail: "",
    port_mail: "",
    secure_mail: "true",
    emailaddress_mail: "",
    password_mail: "",
  });

  useEffect(() => {
    console.log("reduxStated");
    console.log(reduxState);
  }, [reduxState]);

  const [addApiKeyModalShow, setAddApiKeyModalShow] = useState(false);

  const onHide = () => {
    setAddApiKeyModalShow(false);
  };

  let deleteModalClose = () => setDeleteModalShow(false);

  const onMailSettingsChange = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const initializeDelete = (id) => {
    setDeletingId(id);
    setDeleteModalShow(true);
  };

  const deleteapikey = () => {
    dispatch(actions.deleteEmailSettings(deletingId));
  };

  const addMailAccount = async (e) => {
    setErrorMessage(false)
    console.log("frontend call");
    console.log(formData.apikeyname);

    let emailSettings = {
      server_mail: formData.server_mail,
      port_mail: formData.port_mail,
      secure_mail: formData.secure_mail,
      emailaddress_mail: formData.emailaddress_mail,
      password_mail: formData.password_mail
    }

    console.log("emailSettings")
    console.log(emailSettings)

try{
  setStartConnection(true);
    await axios
      .post("/api/users/nodemailerverify/", emailSettings)
      .then((res) => {
        console.log("frontend response");
        console.log(res);

        if(res.data.success === true){
          console.log("connection success");
          setSuccesfulConnection(true)
          dispatch(actions.addEmailSettings(emailSettings))
        }

        else if (res.data.error.code !== ""){
        console.log("connection error");
        setErrorMessage(true)
        SetSocketerrorCode(res.data.error.code);
        SetSocketerrorCommand(res.data.error.command);
        SetSocketerrorReponse(res.data.error.response);
        SetSocketerrorResponseCode(res.data.error.responseCode);
        SetSocketerrorReason(res.data.error.reason);
        setStartConnection(false);
        }
        else{
          SetSocketerrorCode("An Unkown Error Occurred. Please try logging out and logging back in again OR double check the information that you have input. Reminder: You may need to create an 'App Generated Password,' instead of your regular one.");
        }
      })
      .catch((err) => {
        console.log("nodemailer frontend error");
        console.log(err);
      });

    }
    catch{
      console.log("code runtime error within Try Block")
    }


    // dispatch(actions.createAPIKey(formData.apikeyname));
    // setAddApiKeyModalShow(false);
  };

  let headers = ["Server Name", "Usename", "Port", "Secure TLS"];



  let secure_options = [
    {
      label: "True",
      value: "true",
    },
    {
      label: "False",
      value: "false",
    },
  ];

  let volunteerList = (
    <div>
      {reduxState.auth.user.emailaddress_mail === "" ? (
        <div style={{ textAlign: "center", padding: 15 }}>
          <h2 style={{ marginLeft: 20 }} className="noevents">
            No Email Account Added...
          </h2>
        </div>
      ) : (
        <div>
          <table>
            <thead>
              <tr className="prTableRow" style={{ color: "#404040" }}>
                {headers.map((header) => (
                  <th
                    key={header}
                    style={{
                      minWidth: 65,
                      // color: "#484848",
                      color: "#FFFFFF",
                      background: "#404040",
                      paddingTop: 17,
                      paddingLeft: 5,
                      fontFamily: "Verdana",
                      border: ".9px solid darkgray",
                    }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* {associatedUsers.map((item) => ( */}
              {/* {reduxState.auth.user.api_access.map((item) => ( */}
                <tr
                  //   key={item._id}
                  //   onClick={(e) => editVolunteer(item._id)}
                  className="prTableRow"
                  style={{ color: "#404040" }}
                >
                  <td
                    key={reduxState.auth.user.server_mail}
                    className="chapterTableCell chapters_field"
                    style={{
                      borderTop: ".9px solid darkgray",
                      borderBottom: ".9px solid darkgray",
                      fontSize: "13px",
                      paddingRight: 18,
                      paddingTop: 18,
                      paddingBottom: 18,
                      paddingLeft: 10,
                      border: ".9px solid darkgray",
                      fontFamily:
                        "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                      // background: item.status === "Checked In" ? "#b9ecdb" : null,
                    }}
                  >
                    {reduxState.auth.user.server_mail}{" "}
                  </td>
                  <td
                    key={reduxState.auth.user.emailaddress_mail}
                    className="chapterTableCell chapters_field"
                    style={{
                      borderTop: ".9px solid darkgray",
                      borderBottom: ".9px solid darkgray",
                      fontSize: "13px",
                      paddingRight: 18,
                      paddingTop: 18,
                      paddingBottom: 18,
                      paddingLeft: 10,
                      border: ".9px solid darkgray",
                      fontFamily:
                        "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                      // background: item.status === "Checked In" ? "#b9ecdb" : null,
                    }}
                  >
                    {reduxState.auth.user.emailaddress_mail}{" "}
                  </td>

                  <td
                    key={reduxState.auth.user.port_mail}
                    className="chapterTableCell chapters_field"
                    style={{
                      borderTop: ".9px solid darkgray",
                      borderBottom: ".9px solid darkgray",
                      fontSize: "13px",
                      paddingRight: 18,
                      paddingTop: 18,
                      paddingBottom: 18,
                      paddingLeft: 10,
                      border: ".9px solid darkgray",
                      fontFamily:
                        "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                      // background: item.status === "Checked In" ? "#b9ecdb" : null,
                    }}
                  >
                    {reduxState.auth.user.port_mail}{" "}
                  </td>

                  <td
                    key={reduxState.auth.user.secure_tls_mail}
                    className="chapterTableCell chapters_field"
                    style={{
                      borderTop: ".9px solid darkgray",
                      borderBottom: ".9px solid darkgray",
                      fontSize: "13px",
                      paddingRight: 18,
                      paddingTop: 18,
                      paddingBottom: 18,
                      paddingLeft: 10,
                      border: ".9px solid darkgray",
                      fontFamily:
                        "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                      // background: item.status === "Checked In" ? "#b9ecdb" : null,
                    }}
                  >
                    {reduxState.auth.user.secure_tls_mail}{" "}
                  </td>

                  {/* marginBottom is increasing height of table cells here */}
                  <td key={reduxState.auth.user.id}>
                    {/* marginBottom is increasing height of table cells here */}
                    <div>
                      <i
                        style={{ fontSize: 20 }}
                        className="fas fa-remove fa-xl edit_volunteers_remove"
                        onClick={(e) => {
                          console.log("hi");
                          console.log(reduxState.auth.user.id);
                          initializeDelete(reduxState.auth.user.id);
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      ></i>
                    </div>
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );


  let onChangeEditPermission = (e) => {
    console.log("e.value");
    console.log(e.value);

    setFormData({
      ...formData,
      secure_mail: e.value,
    });


    if(e.value === "false"){
      setSecure_default({
        ...secure_default,
        label: e.value[0].toUpperCase() + e.value.slice(1),
        value: e.value
      });
    }
  };

  return (
    <div>
      <div
        className="verifyemailcontainer"
        style={{
          border: "1px solid darkgray",
          padding: 20,
          paddingRight: 24,
          borderRadius: 10,
          paddingBottom: 24,
          width: reduxState.auth.user.emailaddress_mail === "" ? 555 : 'auto'
        }}
      >
        <h3
          className="deleteaccounttitle"
          style={{
            marginTop: "1%",
            color: "#505050",
            fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
          }}
        >
          <b>Outgoing Mail Settings</b>
        </h3>

        <button
          className="verifybutton"
          onClick={() => setAddApiKeyModalShow(true)}
          // disabled={this.state.verifyEmailButton}
          style={{
            marginLeft: "1%",
            marginTop: 15,
          }}
        >
          <b
            style={{
              fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
            }}
          >
            Add a Mail Account
          </b>
        </button>

        {volunteerList}
      </div>

      <Modal
        {...props}
        show={addApiKeyModalShow}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <b color="#909090">Setup your Email Account</b>
          </Modal.Title>
        </Modal.Header>
        {!succesfulConnection ? (
          <Modal.Body>

            <div style={{ fontFamily: "Verdana, Geneva, Helvetica" }}>
              <form addMailAccount={addMailAccount}>
                <div>
                {!startConnection ?
                <div>

                  <div className="form-group">
                    <label htmlFor="server_mail">
                      <b>Server Name/Host: </b>
                    </label>
                    <input
                      id="server_mail"
                      type="text"
                      name="server_mail"
                      className="form-control form-control-md"
                      style={{
                        flex: "8",
                        padding: "5px",
                        border: ".5px solid black",
                      }}
                      defaultValue={formData.server_mail}
                      placeholder="E.g. smtp.example.com..."
                      // defaultValue={formData.name}
                      onChange={onMailSettingsChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="port_mail">
                      <b>Port Number: </b>
                    </label>
                    <input
                      id="port_mail"
                      type="text"
                      name="port_mail"
                      className="form-control form-control-md"
                      style={{
                        flex: "8",
                        padding: "5px",
                        border: ".5px solid black",
                      }}
                      defaultValue={formData.port_mail}
                      placeholder="E.g. 587..."
                      // defaultValue={formData.name}
                      onChange={onMailSettingsChange}
                    />
                  </div>

                  <div style={{ marginBottom: 10 }}>
                    <label className="" htmlFor="secure_mail">
                      <b>Secure SSL? <small>(For TLS Select False)</small>:</b>
                    </label>
                    <Select
                      //   isMulti
                      // defaultValue={secure_default}
                      onChange={onChangeEditPermission}
                      options={secure_options}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="emailaddress_mail">
                      <b>Email Address: </b>
                    </label>
                    <input
                      id="emailaddress_mail"
                      type="text"
                      name="emailaddress_mail"
                      className="form-control form-control-md"
                      style={{
                        flex: "8",
                        padding: "5px",
                        border: ".5px solid black",
                      }}
                      defaultValue={formData.emailaddress_mail}
                      placeholder="E.g. Office, Outlook, Gmail Address..."
                      // defaultValue={formData.name}
                      onChange={onMailSettingsChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password_mail">
                      <b>Password: </b>
                      <span style={{ fontSize: 13 }}>
                        (Note: Many Email Providers may require you to create an
                        App Generated Password.)
                      </span>
                    </label>
                    <input
                      id="password_mail"
                      type="text"
                      name="password_mail"
                      className="form-control form-control-md"
                      defaultValue={formData.password_mail}
                      style={{
                        flex: "8",
                        padding: "5px",
                        border: ".5px solid black",
                      }}
                      placeholder="Password or App Generated Password..."
                      // defaultValue={formData.name}
                      onChange={onMailSettingsChange}
                    />
                  </div>
               </div>
               : null}

                  <div
                    style={{
                      minWidth: 80,
                      paddingRight: 10,
                      color: "#404040",
                      background: "white",
                      paddingTop: 17,
                      paddingLeft: 5,
                      fontFamily: "Verdana",
                    }}
                  >
             {startConnection ? (
            <div
            style={{
              justifyContent: "center",
              marginBottom: "9%",
              marginTop: "10%",
            }}
          >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h2>Establishing Email Connection...</h2>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <BarLoader
              color={"#073980"}
              // loading={loading}
              size={300}
              width={400}
              thickness={100}
              height={20}
              aria-label="Loading Spinner"
              data-testid="loader"
              speedMultiplier={1}
              margin={0}
            />
            </div>
          </div>
            ) : null}
            {errorMessage ?
                    <Alert
                      // className="float-right"
                      style={{
                        // position: "fixed",
                        zIndex: 1,
                        border: ".1px solid gray",
                        fontSize: 14,
                        marginTop: -12,
                      }}
                      severity="error"
                    >
                      <b>Connection Failed with Error Code:</b>
                      {socketerrorCode ? (
                        <span> {socketerrorCode} </span>
                      ) : null}
                      {socketerrorCommand ? (
                        <span>
                          <b>Command:</b> {socketerrorCommand}{" "}
                        </span>
                      ) : null}
                      {socketerrorReponse ? (
                        <span>
                          <b>Response:</b> {socketerrorReponse}{" "}
                        </span>
                      ) : null}
                      {socketerrorResponseCode ? (
                        <span>
                          <b>Response Code:</b> {socketerrorResponseCode}{" "}
                        </span>
                      ) : null}
                      {socketerrorReason ? (
                        <span>
                          <b>Reason:</b> {socketerrorReason}{" "}
                        </span>
                      ) : null}
                    </Alert>
                    : null}
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body>
                                <Alert
                      // className="float-right"
                      style={{
                        // position: "fixed",
                        zIndex: 1,
                        border: ".1px solid gray",
                        fontSize: 15,
                        marginTop: 0,
                        color: "darkgreen",
                        fontWeight: "bold"
                      }}
                      severity="success"
                    >Successful Connection!</Alert>
                    <div style={{marginLeft: 10}}>
            <h4 style={{color:"darkgreen", fontWeight:"bold", marginTop: 10}}
            >Email Account Successfully Added</h4>
            <span style={{display: "flex", fontSize: "15px"}}><b>Server: </b>{formData.server_mail}</span>
            <span style={{display: "flex", fontSize: "15px"}}><b>Port: </b>{formData.port_mail}</span>
            <span style={{display: "flex", fontSize: "15px"}}><b>Secure TLS: </b>{formData.secure_mail}</span>
            <span style={{display: "flex", fontSize: "15px"}}><b>Email Account: </b>{formData.emailaddress_mail}</span>
            </div>
          </Modal.Body>
        )}
        <Modal.Footer>
        {!succesfulConnection ?
          <Button
            style={{
              fontFamily: "Verdana, Geneva, Helvetica",
            }}
            variant="success"
            onClick={addMailAccount}
          >
            Submit
          </Button>
          : null}
          <Button
            style={{
              fontFamily: "Verdana, Geneva, Helvetica",
            }}
            variant="danger"
            onClick={onHide}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <DeleteMailSettings
        show={deleteModalShow}
        // id={activeItemId}
        onHide={deleteModalClose}
        deletingid={deletingId}
        deleteapikey={deleteapikey}
      />
    </div>
  );
};

export default ApiAccess;
