import React, { useEffect, useState } from "react";
import ReactTables from "../../ADA/ReactTable";
import { Popup } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, Redirect } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { Button } from "react-bootstrap";
import csvDownload from "json-to-csv-export";

const Activity = () => {
  const [activitylog, setActivityLog] = useState([]);
  const reduxState = useSelector((state) => state);

  useEffect(() => {
    try {
      axios.get("/api/users/loadactivity").then((res) => {
        // console.log('axios activity response')
        // console.log(res.data.activitylog)
        let activities = res.data.activitylog;
        var tabledata = [...activities];
        // for (let i = 0; i < tabledata?.length; i++) {
        //   console.log(tabledata[i]?.activity_date)
        //   tabledata[i]['activity_date'] = moment(tabledata[i]?.activity_date).format('L')
        // }
        tabledata.sort(
          (d1, d2) =>
            new Date(d2.activity_date).getTime() -
            new Date(d1.activity_date).getTime()
        );
        setActivityLog(tabledata);
      });
    } catch (e) {
      console.log(`activity loading error ${e}`);
    }
  }, []);

  let today_date = moment(Date.now()).format("MM-D-YY");

  const downloadActivity = () => {
    csvDownload(activitylog, `Activity_Report_${today_date}.csv`);

  }

  const columns = [
    {
      accessor: "activity_account_name",
      Header: "User Name",
      Cell: ({ original }) => (
        <Popup
          content={"activity_account_name"}
          trigger={<span>{original?.activity_account_name}</span>}
        />
      ),
    },
    {
      accessor: "activity_crud",
      Header: "Request Type",
      width: 130,
      Cell: ({ original }) => (
        <Popup
          content={"activity_crud"}
          trigger={<span>{original?.activity_crud}</span>}
        />
      ),
    },
    {
      accessor: "activity_date",
      Header: "Date",
      width: 180,
      Cell: ({ original }) => (
        <Popup
          content={"activity_date"}
          trigger={
            <span>
              {moment(original?.activity_date).format("l")}{" "}
              {moment(original?.activity_date).format("LTS")}
            </span>
          }
        />
      ),
    },
    {
      accessor: "activity_description",
      Header: "Description",
      width: 375,
      Cell: ({ original }) => (
        <Popup
          content={"activity_description"}
          trigger={<span>{original?.activity_description}</span>}
        />
      ),
    },
    {
      accessor: "activity_role",
      Header: "Role",
      width: 125,
      Cell: ({ original }) => (
        <Popup
          content={"activity_role"}
          trigger={<span>{original?.activity_role}</span>}
        />
      ),
    },
    {
      accessor: "activity_chapter",
      Header: "Chapter",
      width: 150,
      Cell: ({ original }) => (
        <Popup
          content={"activity_chapter"}
          trigger={<span>{original?.activity_chapter}</span>}
        />
      ),
    },
    {
      accessor: "activity_account_email",
      Header: "Email",
      width: 200,
      Cell: ({ original }) => (
        <Popup
          content={"activity_account_email"}
          trigger={<span>{original?.activity_account_email}</span>}
        />
      ),
    },
    {
      accessor: "activity_account_username",
      Header: "Username",
      width: 200,
      Cell: ({ original }) => (
        <Popup
          content={"activity_account_username"}
          trigger={<span>{original?.activity_account_username}</span>}
        />
      ),
    },
  ];

  let authRedirect = null;
  if (!reduxState?.auth.isAuthenticated) {
    authRedirect = <Redirect to="/" />;
  }

  return (
    <div>
      {authRedirect}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h1
          className="eventlist_titles"
          style={{
            textAlign: "center",
            marginTop: 30,
            marginBottom: 25,
          }}
        >
          <b
            style={{
              fontWeight: "bold",
              fontFamily: "Verdana, Geneva, Helvetica",
            }}
          >
            Activity Log
          </b>
        </h1>

        <div style={{ position: "absolute", right: "40px", marginTop: "35px" }}>
          <Button onClick={downloadActivity} style={{width: '175px'}} variant="info">Download Report</Button>
        </div>
      </div>
      <div style={{ paddingLeft: "3%", paddingRight: "3%" }}>
        {/* {reduxState?.activity.activity ? */}
        <ReactTables
          // style={{border: '2px solid #606060'}}
          filterable
          columns={columns}
          data={activitylog}
          minRows={0}
          defaultPageSize={25}
        />
        {/* : null} */}
      </div>
    </div>
  );
};

export default Activity;
